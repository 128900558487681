<template>
    <div class="root-wrap">
        <div class="body-wrap fx-fill">
            <div class="fill-box">
                <van-tabs v-model="activeIndex" class="fx-column" :scrollspy="false" :lazy-render="false" sticky :ellipsis="false" style="height: 100%;" @change="initPage">
                    <van-tab title="全 部">
                        <div class="movie_tab-wrap fill-box" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
                            <template v-for="item, index in list">
                                <store-order v-model="list[index]" :key="index" @on-pay="onPay" @on-cancel="onCancel" @count-down-finish="countDownFinish" @on-click="toOrderDetail" @contact="onContact"></store-order>
                            </template>
                            <div v-if="list.length > 0 && pagination.next == false" class="fx-middle fx-end">
                                <span class="fc-tips">没有更多的订单啦</span>
                            </div>
                            <van-empty v-else-if="list.length < 1" description="暂无充值订单" image-size="35%"></van-empty>
                        </div>
                    </van-tab>
                    <van-tab title="待付款">
                        <div class="movie_tab-wrap fill-box" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
                            <template v-for="item, index in list">
                                <store-order v-model="list[index]" :key="index" @on-pay="onPay" @on-cancel="onCancel" @count-down-finish="countDownFinish" @contact="onContact"></store-order>
                            </template>
                            <div v-if="list.length > 0 && pagination.next == false" class="fx-middle fx-end">
                                <span class="fc-tips">没有更多的订单啦</span>
                            </div>
                            <van-empty v-else-if="list.length < 1" description="暂无充值订单" image-size="35%"></van-empty>
                        </div>
                    </van-tab>
                    <van-tab title="已付款">
                        <div class="movie_tab-wrap fill-box" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
                            <template v-for="item, index in list">
                                <store-order v-model="list[index]" :key="index" @on-click="toOrderDetail" @contact="onContact"></store-order>
                            </template>
                            <div v-if="list.length > 0 && pagination.next == false" class="fx-middle fx-end">
                                <span class="fc-tips">没有更多的订单啦</span>
                            </div>
                            <van-empty v-else-if="list.length < 1" description="暂无充值订单" image-size="35%"></van-empty>
                        </div>
                    </van-tab>
                    <van-tab title="退 款">
                        <div class="movie_tab-wrap fill-box" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
                            <template v-for="item, index in list">
                                <store-order v-model="list[index]" :key="index" @on-click="toOrderDetail" @contact="onContact"></store-order>
                            </template>
                            <div v-if="list.length > 0 && pagination.next == false" class="fx-middle fx-end">
                                <span class="fc-tips">没有更多的订单啦</span>
                            </div>
                            <van-empty v-else-if="list.length < 1" description="暂无充值订单" image-size="35%"></van-empty>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import { cancelOrder } from '@api/order-request';
import { getOrderList } from '@api/recharge-request'
import StoreOrder from '@/components/user/store-order/index.vue'
import pagination from '@/components/mixins/pagination';
export default {
    mixins: [pagination],
    data() {
        return {
            activeIndex: 0,
            orderStatus: ['', 'N', 'P', 'R'],
            loading: {},
            list: [],
        }
    },
    computed: {
        serviceTel() {
            return this.$store.getters.baseData.serviceTel;
        }
    },
    mounted() {
        if (this.$route.query.index) {
            this.activeIndex = this.$route.query.index;
        }
        this.initPage();
    },
    methods: {
        test() {
            console.log(this.pagination)
        },
        countDownFinish(order) {
            let index = this.list.map(item => item.id).indexOf(order.id);
            this.list.splice(index, 1);
        },
        toOrderDetail(orderId) {
            this.$goPage('store-order-detail', {
                on: orderId
            })
        },
        onPay(on) {
            this.$goPage('store-order-pay', {
                on
            })
        },
        onCancel(orderId) {
            this.$dialog.confirm({
                title: '系统提示',
                message: '确认取消订单吗?'
            }).then(() => {
                let loading = this.$toast.loading(this.$config.loadingMsg);
                cancelOrder({ orderId }).then((res) => {
                    loading.clear();
                    this.initPage();
                })
            })
        },
        loadMore() {
            if (this.loading.value) return;
            if (this.pagination.next) {
                ++this.pagination.pageNo;
                this.getMemberOrderList();
            }
        },
        getMemberOrderList() {
            this.loading = this.$toast.loading(this.$config.loadingMsg);
            let params = {
                pageSize: this.pagination.pageSize,
                pageNo: this.pagination.pageNo
            }
            params.orderStatus = this.orderStatus[this.activeIndex];
            getOrderList(params).then((res) => {
                this.list = this.list.concat(res.records);
                this.copyPagination(res);
                this.loading.clear();
            }).catch((err) => {
                console.log(err)
                this.loading.clear();
            })
        },
        onContact() {
            window.location.href = `tel://${this.serviceTel}`;
        },
        initPage() {
            this.list = [];
            this.getMemberOrderList();
        }
    },
    components: {
        StoreOrder,
    }
}
</script>

<style lang="scss" scoped>
.body-wrap {
	position: relative;
	height: 0px;
	overflow: hidden;
	/deep/ .van-tabs__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		.van-tab__pane {
			flex: 1;
			position: relative;

			.movie_tab-wrap {
				padding: 0 7px;
				padding-top: 7px;
			}
		}
	}
}
</style>

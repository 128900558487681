<template>
    <div class="store-order" @click="onClick">
        <div class="header">
            <div>充值账号：<span style="font-weight: 700;">{{ order.mobile }}</span></div>
            <div :class="statusColor">{{ order.orderStatusText }}</div>
        </div>
        <div class="body fx-row">
            <div>
                <img :src="order.logo" style="width: 80px;height: 80px;"/>
            </div>
            <div class="fx-fill fx-column fx-between" style="padding: 8px;">
                <div class="order-title">
                    {{ order.orderTitle }}
                </div>
                <div class="fc-tips">
                    <div>下单号：{{ order.id }}</div>
                    <div style="margin-top: 4px;">下单时间：{{ order.addTime }}</div>
                </div>
            </div>
            <div class="fx-center fx-middle">
                <span class="price">￥{{ order.amount }}</span>
            </div>
        </div>
        <div class="footer">
            <div>
                <van-button round size="mini" class="btn-mini" @click.stop="$emit('contact')">联系客服</van-button>
            </div>
            <div>
                <div v-if="order.remainTime" class="fx-row fx-around">
                    <van-button round size="mini" class="btn-mini" :color="$config.style.cancelColor" @click="$emit('on-cancel', order.id)">取消订单</van-button>
                    <van-button round size="mini" class="btn-mini" :color="$config.style.statusColor" @click="$emit('on-pay', order.id)">去支付</van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { decimalPoint } from '@/libs/common-utils/common-utils';

export default {
    model: {
        prop: 'order',
        event: 'update',
    },
    props: {
        order: Object,
    },
    computed: {
        statusColor() {
            if (this.order.orderStatus == "N") return 'red';
            if (this.order.orderStatus == "S") return "green";
            if (this.order.orderStatus == "T") return "blue";
            return "";
        }
    },
    methods: {
        decimalPoint,
        onClick() {
            if (this.order.orderStatus == 'T' || this.order.orderStatus == 'S' || this.order.orderStatus == 'R') {
                this.$emit('on-click', this.order.id);
            }
        },
        finish() {
            this.$emit('count-down-finish', this.order);
        }
    }
}
</script>

<style lang="scss" scoped>
.store-order {
	background-color: #fff;
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0px 1px 1px #DCDCDC;
	margin-bottom: 8px;

	.header {
		padding: 6px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .red {
            color: $type-error;
        }

        .green {
            color: $type-success;
        }
        .blue {
            color: #409EFF;
        }
	}

    .body {
        padding: 6px 10px;
    }

    .order-title {
        font-weight: 700;
        font-size: 13px;
    }

    .price {
        font-size: 15px;
        color: $type-warning;
    }
}

.footer {
	border-top: 1px dashed $border-color;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
</style>
